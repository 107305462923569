

export default {
  data () {
    return {
      activeTab: 0,
      internships: null,
      vacancies: null,
    }
  },
  async fetch () {
    const [
      { data: { items: internships = [] } = {}, statusTextInternships },
      { data: { items: vacancies = [] } = {}, statusTextVacancies },
    ] = await Promise.all([
      // Get internships
      await this.$api.recruitment.getAllVacancies({
        department: this.$config.careers.internships.department,
      }),
      // Get vacancies
      await this.$api.recruitment.getAllVacancies({
        department: this.$config.careers.vacancies.department,
      }),
    ])

    if (!internships) {
      this.$log.warn({ statusTextInternships })
    }

    if (!vacancies) {
      this.$log.warn({ statusTextVacancies })
    }

    this.internships = internships
    this.vacancies = vacancies
  },
  computed: {
    hero () {
      let imageUrl = this.$config.heros['werkenbij-home']
      switch (this.activeTab) {
        case 1: // Vacatures
          imageUrl =  this.$config.heros['werkenbij-vacatures']
          break
        case 2: // Stages
          imageUrl =  this.$config.heros['werkenbij-stages']
          break
        case 3: // Vrijwilligers
          imageUrl =  this.$config.heros['werkenbij-vrijwilligers']
      }
      return imageUrl
    },
  },
}
