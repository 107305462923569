// Import constants
import constants from './constants'

// Import additional configs
import sitemap from './sitemap'

const configFiles = {
  sitemap,
}

export {
  constants,
  configFiles,
}
