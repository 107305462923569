import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d9eb9d18 = () => interopDefault(import('../pages/bestuur.vue' /* webpackChunkName: "pages/bestuur" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _cce4213a = () => interopDefault(import('../pages/pers/index.vue' /* webpackChunkName: "pages/pers/index" */))
const _a53b6f64 = () => interopDefault(import('../pages/uitschrijven.vue' /* webpackChunkName: "pages/uitschrijven" */))
const _89649d16 = () => interopDefault(import('../pages/verantwoording-en-jaarverslagen.vue' /* webpackChunkName: "pages/verantwoording-en-jaarverslagen" */))
const _3fe305da = () => interopDefault(import('../pages/werken-bij/index.vue' /* webpackChunkName: "pages/werken-bij/index" */))
const _32676224 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _2bfb0552 = () => interopDefault(import('../pages/contact/adres-wijzigen.vue' /* webpackChunkName: "pages/contact/adres-wijzigen" */))
const _2b8dae23 = () => interopDefault(import('../pages/contact/adverteren.vue' /* webpackChunkName: "pages/contact/adverteren" */))
const _344142d8 = () => interopDefault(import('../pages/contact/andere-vraag.vue' /* webpackChunkName: "pages/contact/andere-vraag" */))
const _5bb15dd4 = () => interopDefault(import('../pages/contact/blad-niet-ontvangen.vue' /* webpackChunkName: "pages/contact/blad-niet-ontvangen" */))
const _c47e5b8e = () => interopDefault(import('../pages/contact/mening-geven.vue' /* webpackChunkName: "pages/contact/mening-geven" */))
const _046c714e = () => interopDefault(import('../pages/contact/opzeggen.vue' /* webpackChunkName: "pages/contact/opzeggen" */))
const _065a81b3 = () => interopDefault(import('../pages/contact/overlijden-doorgeven.vue' /* webpackChunkName: "pages/contact/overlijden-doorgeven" */))
const _5564814e = () => interopDefault(import('../pages/contact/verzonden.vue' /* webpackChunkName: "pages/contact/verzonden" */))
const _3e27a82f = () => interopDefault(import('../pages/pers/berichten.vue' /* webpackChunkName: "pages/pers/berichten" */))
const _c947611c = () => interopDefault(import('../pages/pers/contactpersonen/index.vue' /* webpackChunkName: "pages/pers/contactpersonen/index" */))
const _52aed7ea = () => interopDefault(import('../pages/pers/gebruikersvoorwaarden.vue' /* webpackChunkName: "pages/pers/gebruikersvoorwaarden" */))
const _f4497664 = () => interopDefault(import('../pages/pers/presentatoren/index.vue' /* webpackChunkName: "pages/pers/presentatoren/index" */))
const _168471c6 = () => interopDefault(import('../pages/pers/programmas/index.vue' /* webpackChunkName: "pages/pers/programmas/index" */))
const _3edb4b47 = () => interopDefault(import('../pages/werken-bij/stages/index.vue' /* webpackChunkName: "pages/werken-bij/stages/index" */))
const _5853b24c = () => interopDefault(import('../pages/werken-bij/vacatures/index.vue' /* webpackChunkName: "pages/werken-bij/vacatures/index" */))
const _5b397e7a = () => interopDefault(import('../pages/werken-bij/vrijwilligers.vue' /* webpackChunkName: "pages/werken-bij/vrijwilligers" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ccb4a3ac = () => interopDefault(import('../pages/pers/contactpersonen/_slug.vue' /* webpackChunkName: "pages/pers/contactpersonen/_slug" */))
const _f7b6b8f4 = () => interopDefault(import('../pages/pers/presentatoren/_slug.vue' /* webpackChunkName: "pages/pers/presentatoren/_slug" */))
const _19f1b456 = () => interopDefault(import('../pages/pers/programmas/_slug.vue' /* webpackChunkName: "pages/pers/programmas/_slug" */))
const _3d24a9ff = () => interopDefault(import('../pages/werken-bij/stages/_slug.vue' /* webpackChunkName: "pages/werken-bij/stages/_slug" */))
const _5bc0f4dc = () => interopDefault(import('../pages/werken-bij/vacatures/_slug.vue' /* webpackChunkName: "pages/werken-bij/vacatures/_slug" */))
const _3ec78eed = () => interopDefault(import('../pages/artikel/_slug.vue' /* webpackChunkName: "pages/artikel/_slug" */))
const _23d852c6 = () => interopDefault(import('../pages/campagne/_slug/index.vue' /* webpackChunkName: "pages/campagne/_slug/index" */))
const _d05163ca = () => interopDefault(import('../pages/pers/_slug.vue' /* webpackChunkName: "pages/pers/_slug" */))
const _8b6e74ea = () => interopDefault(import('../pages/campagne/_slug/line-up/index.vue' /* webpackChunkName: "pages/campagne/_slug/line-up/index" */))
const _20f28bfa = () => interopDefault(import('../pages/campagne/_slug/nieuws.vue' /* webpackChunkName: "pages/campagne/_slug/nieuws" */))
const _b93f6026 = () => interopDefault(import('../pages/campagne/_slug/line-up/_person.vue' /* webpackChunkName: "pages/campagne/_slug/line-up/_person" */))
const _1ff41c37 = () => interopDefault(import('../pages/campagne/_slug/_field.vue' /* webpackChunkName: "pages/campagne/_slug/_field" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bestuur",
    component: _d9eb9d18,
    pathToRegexpOptions: {"strict":true},
    name: "bestuur"
  }, {
    path: "/contact",
    component: _1d238929,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/pers",
    component: _cce4213a,
    pathToRegexpOptions: {"strict":true},
    name: "pers"
  }, {
    path: "/uitschrijven",
    component: _a53b6f64,
    pathToRegexpOptions: {"strict":true},
    name: "uitschrijven"
  }, {
    path: "/verantwoording-en-jaarverslagen",
    component: _89649d16,
    pathToRegexpOptions: {"strict":true},
    name: "verantwoording-en-jaarverslagen"
  }, {
    path: "/werken-bij",
    component: _3fe305da,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij"
  }, {
    path: "/zoeken",
    component: _32676224,
    pathToRegexpOptions: {"strict":true},
    name: "zoeken"
  }, {
    path: "/contact/adres-wijzigen",
    component: _2bfb0552,
    pathToRegexpOptions: {"strict":true},
    name: "contact-adres-wijzigen"
  }, {
    path: "/contact/adverteren",
    component: _2b8dae23,
    pathToRegexpOptions: {"strict":true},
    name: "contact-adverteren"
  }, {
    path: "/contact/andere-vraag",
    component: _344142d8,
    pathToRegexpOptions: {"strict":true},
    name: "contact-andere-vraag"
  }, {
    path: "/contact/blad-niet-ontvangen",
    component: _5bb15dd4,
    pathToRegexpOptions: {"strict":true},
    name: "contact-blad-niet-ontvangen"
  }, {
    path: "/contact/mening-geven",
    component: _c47e5b8e,
    pathToRegexpOptions: {"strict":true},
    name: "contact-mening-geven"
  }, {
    path: "/contact/opzeggen",
    component: _046c714e,
    pathToRegexpOptions: {"strict":true},
    name: "contact-opzeggen"
  }, {
    path: "/contact/overlijden-doorgeven",
    component: _065a81b3,
    pathToRegexpOptions: {"strict":true},
    name: "contact-overlijden-doorgeven"
  }, {
    path: "/contact/verzonden",
    component: _5564814e,
    pathToRegexpOptions: {"strict":true},
    name: "contact-verzonden"
  }, {
    path: "/pers/berichten",
    component: _3e27a82f,
    pathToRegexpOptions: {"strict":true},
    name: "pers-berichten"
  }, {
    path: "/pers/contactpersonen",
    component: _c947611c,
    pathToRegexpOptions: {"strict":true},
    name: "pers-contactpersonen"
  }, {
    path: "/pers/gebruikersvoorwaarden",
    component: _52aed7ea,
    pathToRegexpOptions: {"strict":true},
    name: "pers-gebruikersvoorwaarden"
  }, {
    path: "/pers/presentatoren",
    component: _f4497664,
    pathToRegexpOptions: {"strict":true},
    name: "pers-presentatoren"
  }, {
    path: "/pers/programmas",
    component: _168471c6,
    pathToRegexpOptions: {"strict":true},
    name: "pers-programmas"
  }, {
    path: "/werken-bij/stages",
    component: _3edb4b47,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij-stages"
  }, {
    path: "/werken-bij/vacatures",
    component: _5853b24c,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij-vacatures"
  }, {
    path: "/werken-bij/vrijwilligers",
    component: _5b397e7a,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij-vrijwilligers"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/pers/contactpersonen/:slug",
    component: _ccb4a3ac,
    pathToRegexpOptions: {"strict":true},
    name: "pers-contactpersonen-slug"
  }, {
    path: "/pers/presentatoren/:slug",
    component: _f7b6b8f4,
    pathToRegexpOptions: {"strict":true},
    name: "pers-presentatoren-slug"
  }, {
    path: "/pers/programmas/:slug",
    component: _19f1b456,
    pathToRegexpOptions: {"strict":true},
    name: "pers-programmas-slug"
  }, {
    path: "/werken-bij/stages/:slug",
    component: _3d24a9ff,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij-stages-slug"
  }, {
    path: "/werken-bij/vacatures/:slug",
    component: _5bc0f4dc,
    pathToRegexpOptions: {"strict":true},
    name: "werken-bij-vacatures-slug"
  }, {
    path: "/artikel/:slug?",
    component: _3ec78eed,
    pathToRegexpOptions: {"strict":true},
    name: "artikel-slug"
  }, {
    path: "/campagne/:slug",
    component: _23d852c6,
    pathToRegexpOptions: {"strict":true},
    name: "campagne-slug"
  }, {
    path: "/pers/:slug",
    component: _d05163ca,
    pathToRegexpOptions: {"strict":true},
    name: "pers-slug"
  }, {
    path: "/campagne/:slug?/line-up",
    component: _8b6e74ea,
    pathToRegexpOptions: {"strict":true},
    name: "campagne-slug-line-up"
  }, {
    path: "/campagne/:slug?/nieuws",
    component: _20f28bfa,
    pathToRegexpOptions: {"strict":true},
    name: "campagne-slug-nieuws"
  }, {
    path: "/campagne/:slug?/line-up/:person?",
    component: _b93f6026,
    pathToRegexpOptions: {"strict":true},
    name: "campagne-slug-line-up-person"
  }, {
    path: "/campagne/:slug?/:field",
    component: _1ff41c37,
    pathToRegexpOptions: {"strict":true},
    name: "campagne-slug-field"
  }, {
    path: "/*",
    component: _2efaf336,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
