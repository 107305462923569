
export default {
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      drawerBackgroundImage: '',
      headerActiveTab: null,
      keyword: null,
      menuPrimaryItems: {
        'Over ons': {
          children: {
            'Missie en ons verhaal': '/missie-en-ons-verhaal',
            'Bestuur': '/bestuur',
            'Verantwoording en jaarverslagen': '/verantwoording-en-jaarverslagen',
          },
        },
        'Werken bij': {
          children: {
            'Ontdek de EO': '/werken-bij',
            'Vacatures': '/werken-bij/vacatures',
            'Stages': '/werken-bij/stages',
            'Vrijwilligers': '/werken-bij/vrijwilligers',
          },
        },
        Pers: {
          children: {
            'Home': '/pers',
            'Persberichten': '/pers/berichten',
            'Programma\'s': '/pers/programmas',
            'Presentatoren': '/pers/presentatoren',
            'Contactpersonen': '/pers/contactpersonen',
          },
        },
        Contact: '/contact',
      },
      menuSecondaryItems: {
        'Word lid': `${this.$config.baseUrlMembership}?utm_source=eo.nl&utm_medium=menu`,
        'Programma\'s': `${this.$config.baseUrlEoHome}/programmas`,
        'Privacyverklaring': this.$config.baseUrlPrivacy,
        'Word partner': '/campagne/word-partner',
      },
      headerTabItemsPress: [
        {
          url: '/pers',
          label: 'Pers home',
          exact: true,
        },
        {
          url: '/pers/berichten',
          label: 'Persberichten',
          exact: false,
        },
        {
          url: '/pers/programmas',
          label: 'Programma\'s',
          exact: false,
        },
        {
          url: '/pers/presentatoren',
          label: 'Presentatoren',
          exact: false,
        },
        {
          url: '/pers/contactpersonen',
          label: 'Contactpersonen',
          exact: false,
        },
      ],
      footerShortcuts: {
        'Over de EO': {
          'Over ons': '/',
          'Werken bij': '/werken-bij',
          Pers: '/pers',
          Contact: '/contact',
        },
        'Wat wij doen': {
          'Programma\'s': `${this.$config.baseUrlEoHome}/programmas`,
          Podcasts: `${this.$config.baseUrlEoHome}/podcasts`,
        },
      },
    }
  },
  computed: {
    isPressEnvironment () {
      return this.$route.path.startsWith('/pers')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
