export default {
  methods: {
    workingHours (minHours, maxHours) {
      let hours = ''
      if (minHours) {
        hours = minHours
      }
      if (maxHours && maxHours !== minHours) {
        if (hours) {
          hours += ' uur - '
        }
        hours += maxHours
      }
      if (hours) {
        hours += ' uur'
      }
      return hours
    },
  },
}
