import axios from 'axios'
import constants from './constants'

const maxBatchSize = 1000 // Amount of items per call (Prepr only returns a maximum of 1000 items per call)
const maxPageNumber = 25  // Amount of sitemap-pages per resource (for now only implemented for articles)

/**
 * Function to get publications from the Prepr REST API recursively without limitations
 * @param {String} resource
 * @param {Int} offset
 * @returns {Array} Slugs of publications
 */
const get = async (config) => {
  const parameters = {
    domain: constants.PREPR_ENVIRONMENT_DOMAIN,
    showLastMod: true,
    ...config,
  }

  const {
    type,
    domain,
    priority,
    resource,
    showLastMod,
    communityId,
    maxAmount,
    page,
    basePath,
  } = parameters

  let limit
  let offset = 0

  const apiHost = process.env.NEW_EO_API_HOST || 'https://api.eo.nl'

  if (page) {
    // 1 determine total amount of articles
    let {data: {meta}} = await axios.get(`${apiHost}/sitemap/${resource}`, {
      params: {
        communityId,
        domain,
        limit: 1,
        offset: 0,
      },
    })
    // 2 determine the page size.
    limit = Math.ceil(meta.total / maxPageNumber)
    offset = (page - 1) * limit
  } else {
    offset = 0
    limit = maxBatchSize > maxAmount ? maxAmount : maxBatchSize
  }

  const params = {
    domain,
    limit,
    offset,
    ...(communityId && { communityId }),
    ...(type === 'rss' && { rss: 'true' }),
  }

  let {data: {items = [] }} = await axios.get(`${apiHost}/sitemap/${resource}`, {
    params, 
  })


  // check if api call contains results
  if (!items || !items[0]) {
    return []
  }

  // Remove publications excluded from indexing
  switch (resource) {
    case 'articles':
    case 'pages':
    case 'events':
    case 'persons':
    case 'programs':
    case 'podcasts':
    case 'campaigns':
    case 'donations':
      items = items.filter(p => {
        return !p.seoAllow || p.seoAllow === true
      })
      break
    case 'topics':
    case 'pressreleases':
    case 'tags':
    case 'stories':
    default:
      break
  }

  // return all publications including the recursive ones
  // last fetched publications (from items) needs to be mapped first
  return [
    ...items.map(p => {
      const fullPath = `${basePath}/${p.slug}`
      return {
        lastmod: showLastMod && (p.changedOn || null),
        priority,
        url: fullPath,
      }
    }),
  ]
}

/**
 * Prevent duplicate url's in the sitemap by excluding the prepr-pages urls
 * from the default sitemap. Therefore, this function combines the two
 * exclude-arrays to one
 * @param {*} routes The routes
 * @param [*] customExcludes  any additional routes to exclude
 * @returns [*] Combined array of urls to exclude from the default sitemap.
 */
const getExcludeUrls = (routes, customExcludes = []) => {
  const exludedPages = routes.map(item => item.url)
  return [...customExcludes, ...exludedPages]
}

/**
 * Default export retuns a sitemap configuration
 * Docs: https://github.com/nuxt-community/sitemap-module
 *
 * @returns {*} A complete sitemap configuration for the application
 */
export default async function () {
  const pagesRoutes = await get({
    resource: 'pages',
    basePath: '/',
  })

  return await [
    {
      path: '/sitemap-index.xml',
      hostname: constants.BASE_URL_CANONICAL,
      lastmod: new Date().toISOString(),
      gzip: true,
      sitemaps: [
        /**
         * sitemap.xml contain the nuxt-routes, exept the ones we can fetch from the cms
         */
        {
          path: '/sitemap.xml',
          exclude: getExcludeUrls(pagesRoutes, ['/pers', '/pers/**']),
        },
        {
          path: '/sitemap-articles.xml',
          exclude: ['/**'],
          routes: async () => {
            return await get({
              resource: 'articles',
              basePath: '/artikel',
            })
          },
        },
        /**
         * sitemap-pages.xml contain the pages from the cms, except the ones nuxt allready figured out
         */
        {
          path: '/sitemap-pages.xml',
          exclude: ['/**'],
          routes: pagesRoutes,
          cacheTime: 1000 * 60 * 60, // One hour cache
        },
      ],
    },
    /**
     * sitemap-index-ss360.xml is our "private" sitemap , to be fed to our own search engine (eg SiteSearch360)
     */
    {
      path: '/sitemap-index-ss360.xml',
      hostname: constants.BASE_URL_CANONICAL,
      lastmod: new Date().toISOString(),
      gzip: true,
      sitemaps: [
        {
          path: '/sitemap-programs-ss360.xml',
          exclude: ['/**'],
          routes: async () => {
            return await get({
              resource: 'programs',
              domain: 'generieke-content-eo',
              basePath: '/pers/programmas',
            })
          },
          cacheTime: 1000 * 60 * 60, // One hour cache
        },
        {
          path: '/sitemap-pressreleases-ss360.xml',
          exclude: ['/**'],
          routes: async () => {
            return await get({
              resource: 'pressreleases',
              basePath: '/pers',
            })
          },
          cacheTime: 1000 * 60 * 60, // One hour cache
        },
      ],
    },
  ]
}
