/**
 * Repository (API) factory function built on axios
 * @requires axios, bugsnag
 * @param {Object} httpClient - Nuxt axios plugin
 * @param {Object} reporter - Nuxt bugsnag plugin
 * @param {String} resource - repository resource name
 * @returns {Object} repository - axios API request wrapper Object that exposes API methods
 * @example legacyFunctions($axios, 'programs') // returns {repository} with resource 'programs'
 * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
 */
export const legacy = (api, resource) => {
  return {
    /**
     * gets all resources
     * @method all
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.programs.getAll({ slug: 'tycho-in-de-ghb' }) // returns response
     */
    getAll(params) {
      return api.get(`${resource}`, { params })
    },

    /**
     * gets all on a specific path of the resource
     * @param {String} path
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.salesforce.getPathAll('/campaigns', {}) // returns response
     */
    getPathAll(path, params) {
      return api.get(`${resource}${path}`, { params })
    },

    /**
     * gets single resource by id
     * @method getOne
     * @param {String} id - id of requested resource
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: item }
     * @example $api.programs.getOne('s234ewe20948') // returns response
     */
    getOne(id, params) {
      return api.get(`${resource}/${id}`, { params })
    },

    /**
     * gets single resource by slug
     * @method getOneBySlug
     * @param {String} slug - slug of requested resource
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: item }
     * @example $api.topics.getOneBySlug('duurzaamheid', { domain: 'home-eo' }) // returns response
     */
    getOneBySlug(slug, params) {
      return api.get(`${resource}?slug=${slug}`, { params })
    },

    /**
     * gets all nested items as part of resource
     * @method getAllNested
     * @param {String} nestedResource - type name of nested resource
     * @param {String} id - id of requested nested resource
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.programs.getAllNested('persons', { limit: 3, offset: 0 }) // returns response (data: { items: {...} } )
     */
    getAllNested(nestedResource, id, params) {
      return api.get(`${resource}/${id}/${nestedResource}`, { params })
    },

    /**
     * gets all related items of type relatedResource
     * @method getAllRelated
     * @param {String} relatedResource - type name of the related resource
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.related.getAllRelated('pressreleases', { selfId: 'w', programId: 'x', topicId: 'y', presenterId: 'z' })
     */
    getAllRelated(relatedResource, params) {
      return api.get(`related/${relatedResource}`, { params })
    },

    /**
     * gets all publications per channel based on the channel and models given as params (or default models if no models are specified)
     * @method getAllByChannel
     * @param {String} channel - channel name of desired channel
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.publications.getAllByChannel('channel', { models: 'x,y,z' })
     */
    getAllByChannel(channel, params) {
      return api.get(`publications-by-channel/${channel}`, { params })
    },

    /**
     * gets single nested resource by resource type ("nestedResource") and resourceId
     * @method one
     * @param {String} nestedResource - nested resource type
     * @param {String} id - id of requested nested resource
     * @returns {Promise} response<Object> { data: item }
     * @example $api.programs.getOneNested('person', 2873) // returns response (data: { item: {...} })
     */
    getOneNested(nestedResource, id) {
      return api.get(`${resource}/${nestedResource}/${id}`)
    },

    /**
     * posts a single model to resource
     * The model must contain the fields: from, to, subject, ...mailFields
     * @method
     * @param {Object} model - post request body
     * @returns {Promise} - response<Object> { data: { POSTMARK_RESPONSE<Object> } }
     * @example $api.mailform.postResource(model) // returns response (data: { message: 'OK' })
     */
    postResource(model) {
      return api.post(`${resource}`, model)
    },

    /**
     * post a body to a specific path on the resource
     * @param {String} path
     * @param {Object} body
     * @returns @returns {Promise} - response<Object> { data: { <Object> } }
     * @example $api.salesforce.postPathBody('/addtocampaign', { campaignId: '7018E000000lMsOQAU', email: 'herman.kremer@eo.nl' })
     * $api.salesforce.postPathBody('/optoutfromcampaign', { campaignId: '7018E000000lMsOQAU', email: 'herman.kremer@eo.nl', contactId: '00Q8E000006yEreUAE' })
     */
    postPathBody(path, body) {
      return api.post(`${resource}${path}`, body)
    },
  }
}

export const basic = (api, resource) => ({
  /**
   * gets all resources
   * @method all
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.programs.getAll({ slug: 'tycho-in-de-ghb' }) // returns response
   */
  getAll(params) {
    return api.get(`${resource}`, { params })
  },

  /**
   * gets single resource by id
   * @method getSingle
   * @param {String} id - id of requested resource
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: item }
   * @example $api.programs.getSingle('s234ewe20948') // returns response
   */
  getSingle(id, params) {
    return api.get(`${resource}/${id}`, { params })
  },

  /**
   * gets single resource by slug
   * @method getOneBySlug
   * @param {String} slug - slug of requested resource
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: item }
   * @example $api.topics.getOneBySlug('duurzaamheid', { domain: 'home-eo' }) // returns response
   */
  getOneBySlug(slug, params) {
    return api.get(`${resource}?slug=${slug}`, { params })
  },
})

export const containerdorp = (api, resource) => ({
  /**
   * Get all answers to a question
   * @method getAnswers
   * @param {String} questionId
   * @returns {Promise} response<Object> {  }
   * @example $api.containerdorp.getAnswers(4)
   */
  getAnswers(questionId) {
    return api.get(`interaction/${resource}/answers`, {
      params: {
        questionId,
      },
    })
  },
  /**
   * Submit an answer to a question
   * @method postAnswer
   * @param {String} questionId
   * @param {Boolean} answer
   * @returns {Promise}
   * @example $api.containerdorp.postAnswer(4, true)
   */
  postAnswer(questionId, answer) {
    const uniqueResponseId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    return api.put(`interaction/${resource}/answers/${uniqueResponseId}`, {
      questionId: String(questionId),
      answer,
    })
  },
})

export const crm = (api, resource) => ({
  /**
   * gets all on a specific path of the resource
   * @param {String} path
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.crm.getPathParam('/relations', {email: 'iemand@eo.nl', customerid: 123}) // returns response
   */
  getPathParam(path, params) {
    return api.get(`${resource}${path}`, { params })
  },
  /**
   * post a body to a specific path on the resource
   * @param {String} path
   * @param {Object} body
   * @returns @returns {Promise} - response<Object> { data: { <Object> } }
   * @example
   * $api.crm.postPathBody('/addtocampaign', { campaignId: '7018E000000lMsOQAU', email: 'iemand@eo.nl' })
   */
  postPathBody(path, body) {
    return api.post(`${resource}${path}`, body)
  },
})

export const hokjesexperiment = (api, resource) => ({
  /**
   * Get all answers to a question
   * @method getAnswers
   * @param {String} questionId
   * @returns {Promise} response<Object> {  }
   * @example $api.hokjesexperiment.getAnswers(4)
   */
  getAnswers(questionId) {
    return api.get(`interaction/${resource}/answers`, {
      params: {
        questionId,
      },
    })
  },
  /**
   * Submit an answer to a question
   * @method postAnswer
   * @param {String} questionId
   * @param {Boolean} answer
   * @returns {Promise}
   * @example $api.hokjesexperiment.postAnswer(4, true)
   */
  postAnswer(questionId, answer) {
    const uniqueResponseId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    return api.put(`interaction/${resource}/answers/${uniqueResponseId}`, {
      questionId: String(questionId),
      answer,
    })
  },
})

export const interaction = (api) => ({
    /**
   * gets all resources
   * @method all
   * @param {String} messages with prompt to ask chatgpt
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.interaction.postChatGptResponse({ messages: [
      { role: 'system', content: 'Je bent een dominee' },
      { role: 'user', content: 'Geef me een random bijbel vers het format JSON' },
      { role: 'assistant', content: 'Want God heeft ons niet gegeven een geest van vreesachtigheid, maar van kracht, liefde en bezonnenheid', },
      { role: 'user', content: 'staat dit in Timoteus' },
    ]})
   */
  postChatGptResponse(messages) {
    return api.post(`interaction/chatgpt`, {
        messages,
    })
  },
})

export const mixedarticles = (api, resource) => ({
  /**
   * gets all resources
   * @method all
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
  * @example $api.mixedarticles.getAll({ excludedDomains: 'beam-eo'}) // returns response
   */
  getAll(params) {
    return api.get(`${resource}`, { params })
  },
})

export const polls = (api, resource) => ({
  /**
   * Get all answers to a question
   * @method getAnswers
   * @param { String } slug
   * @param { Number } questionId
   * @returns { Promise } response<Object> {  }
   * @example $api.polls.getAnswers('dit-is-poll-1', 1)
   */
  getAnswers(slug, questionId) {
    return api.get(`interaction/${resource}/answers`, {
      params: {
        slug,
        questionId,
      },
    })
  },
  /**
   * Submit an answer to a question
   * @method postAnswer
   * @param { String } slug
   * @param { Number } questionId
   * @param { String } answer
   * @returns { Promise }
   * @example $api.polls.postAnswer('dit-is-poll-1', 1, 'a')
   */
  postAnswer(slug, questionId, answer) {
    return api.put(`interaction/${resource}/answers/${slug}`, {
      slug: slug,
      questionId: questionId,
      answer,
    })
  },
})

export const recommendations = (api, resource) => ({
  /**
   * Get similar recommendations
   * @method getSimilar
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.recommendations.getSimilar( { id: '723b97a8-67bf-4d3c-8815-9b5970323617', resource: 'articles' }) // returns response
   */
  getSimilar(params) {
    return api.get(`${resource}/similarcontentitems`, { params })
  },
  /**
   * Get popular recommendations
   * @method getPopular
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.recommendations.getPopular( { id: '723b97a8-67bf-4d3c-8815-9b5970323617', resource: 'articles' }) // returns response
   */
  getPopular(params) {
    return api.get(`${resource}/popularcontentitems`, { params })
  },
})

export const songsdb = (api, resource) => ({
  /**
   * get one collection with its songs
   *
   * @param {String} mid
   * @param {Object} params
   *
   * @returns {Promise} response<Object> { }
   *
   * @example $api.songsdb.getOneCollection('POMS_S_EO_16964090')
   */
  getOneCollection(mid, params) {
    return api.get(`${resource}/collections/${mid}`, { params })
  },

  /**
   * get database information about available filter options
   * @method getDatabaseInfo
   * @returns {Promise} response<Object> {  }
   * @example $api.songsdb.getDatabaseInfo()
   */
  getDatabaseInfo() {
    return api.get(`${resource}/info`)
  },

  /**
   * get all playlists defined in songs db
   * @param params
   * @returns {Promise} response<Object> {  }
   * @example $api.songsdb.getAllPlaylists({limit: 10, offset: 0, sort: '-publish_on'})
   */
  getAllPlaylists(params) {
    return api.get(`${resource}/playlists`, { params })
  },

  /**
   * get one playlist with its songs
   * @param mid
   * @param params
   * @returns {Promise} response<Object> {  }
   * @example $api.songsdb.getOnePlaylist('POMS_S_EO_16393456')
   */
  getOnePlaylist(mid, params) {
    return api.get(`${resource}/playlists/${mid}`, { params })
  },

  /**
   * gets all songs
   * @method getAllSongs
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> {  }
   * @example $api.songsdb.getAllSongs({ limit: 5, filterName: 'NZ_BUNDLE', filterValue: 'Liedboek voor de Kerken', filterByTagValue: 'Rouw' }) // returns response
   */
  getAllSongs(params) {
    return api.get(`${resource}/songs`, { params })
  },

  /**
   * gets single song by mid
   * @method getOne
   * @param {String} mid - mid of requested resource
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> {  }
   * @example $api.songsdb.getOneSong('WO_EO_16875549') // returns response
   */
  getOneSong(mid, params) {
    return api.get(`${resource}/songs/${mid}`, { params })
  },
})

/**
 * Repository (API) factory function built on axios for NPO endpoints
 * @requires axios, bugsnag
 * @param {Object} httpClient - Nuxt axios plugin
 * @param {Object} reporter - Nuxt bugsnag plugin
 * @param {String} resource - repository resource name
 * @returns {Object} repository - axios API request wrapper Object that exposes API methods
 * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
 */
export const npo = (api) => ({
  /**
   * getEmbed
   * @param {String} mid - Poms media id
   * @param {Object} params - optional parameters for API request
   *  {
   *    overlayUrl: '',
   *    hasAdConsent: 0,
   *    noAds: 0,
   *    referralUrl: '',
   *    sterSiteId: 'eoportal',
   *    sterIdentifier: 'eoportal-desktop',
   *    atinternetSiteId: '59', // Level 2 ID of AT Internet to identify the site
   *    atinternetUserId: '',   // user ID for AT internet (The user ID must be filled in
   *                               during the user’s entire visit (on all hits). This ID is stored in a cookie on the
   *                               domain indicated in the Tracker settings (cookieDomain variable), in order to be
   *                               re-used on pages where it is not filled in.)
   *    atinternetUserIdCookieDuration: '222', // user ID cookie duration for AT internet, in days, 397 by default
   *    stylesheetUrl: ''
   *  }
   *  To get values for atinternetUserId and atinternetUserIdCookieDuration
   *  you will need to parse cookie with the name atuserid
   *  Documentation https://wiki.publiekeomroep.nl/display/npoplayer/Web+player+documentatie
   * @returns {Promise} response<Object> { embed: embedCode }
   * @example $api.npo.getEmbed('POMS_EO_3763423', { referralUrl: 'https://portal.eo.nl', overlayUrl: 'https://../file.jpg' })
   */
  getEmbed(mid, params) {
    return api.get(`npoplayer`, {
      params: {
        mid,
        ...params,
      },
    })
  },
  /**
   *
   * @param {String} mid - Poms media id
   * @param {Object} params - optional parameters for API request
   *
   * "options": {
   *     "id": "npo-player-123",
   *     "autoplay": 0,
   *     "color": "#FF69B4",
   *     "hasAdConsent": 0,
   *     "noAds": 0,
   *     "recommendations": 0,
   *     "endscreen": 0,
   *     "nextEpisode": 0,
   *     "share": 1,
   *     "shareUrl": "https://eo.nl",
   *     "referralUrl": "https://eo.nl",
   *     "sterReferralUrl": "https://eo.nl",
   *     "sterSiteId": "eoportal",
   *     "sterIdentifier": "eoportal-desktop",
   *     "pageUrl": "https://eo.nl",
   *     "smarttag": {
   *       "siteId": "59",
   *       "userId": "123",
   *       "userIdCookieDuration": "300",
   *       "playerName": "npohtml5",
   *       "playerVersion": "8.0"
   *     },
   *     "stylesheet": "https://visie.eo.nl/typo3conf/ext/eo_npo/Resources/Public/Css/playerstyling.css?version=2.0",
   *     "styling": {
   *       "title": {
   *         "font": "Comic Sans",
   *         "size": "36px",
   *         "color": "white"
   *       },
   *       "subTitle": {
   *         "font": "Comic Sans",
   *         "size": "36px",
   *         "color": "white",
   *         "display": "block"
   *       },
   *       "nicam": {
   *         "size": "36px",
   *         "color": "white",
   *         "display": "block"
   *       },
   *       "extraInfo": {
   *         "font": "Comic Sans",
   *         "size": "36px",
   *         "color": "white",
   *         "display": "block"
   *       }
   *     },
   *     "trackProgress": 0,
   *     "overlay": "https://visie.eo.nl/fileadmin/bestanden-2017/_processed_/9/2/csm_Koninging_Maxima_en_Matthijs_van_Nieuwkerk_7d81379663.jpg"
   *   }
   *
   * @return {Promise} response<Object> { embed: embedCode, embedUrl: url }
   */
  postEmbedOptions(mid, params) {
    return api.post(`npo/npoplayerembed`, {
      params: {
        mid,
        ...params,
      },
    })
  },
})

/**
 * Repository (API) factory function built on axios for payments endpoints
 *
 * @param httpClient
 * @param reporter
 * @param resource
 *
 * @return {{getListOfBanks(String): (*|undefined)}}
 */
export const payments = (api, resource) => ({
  /**
   * Get list of available banks
   *
   * @method getListOfBanks
   *
   * @param {String} organisation
   *
   * @returns {Promise}
   *
   * @example $api.payments.getListOfBanks('metterdaad')
   */
  getListOfBanks(organisation) {
    return api.get(`${resource}/banks`, {
      params: {
        organisation,
      },
    })
  },
  /**
   * Get a redirect url for ideal payment
   *
   * @method postIdealPaymentToBuckaroo
   * @param {Object} body
   *
   * @returns {Promise}
   *
   * @example $api.payments.postIdealPaymentToBuckaroo({
   *   "buckaroo": {
   *     "bank": "ABNANL2A",
   *     "returnUrl": "https://www.eo.nl?success=true",
   *     "returnUrlCancel": "https://www.eo.nl?cancel=true",
   *     "returnUrlError": "https://www.eo.nl?error=true",
   *     "returnUrlReject": "https://www.eo.nl?reject=true"
   *   },
   *   "product": {
   *     "organistaion": "MTD",
   *     "salesforceProduct": "MTD_1X_ALG|MTD_ALG_1X|STD",
   *     "quantity": "1",
   *     "productDescription": "Word donateur van EO Metterdaad",
   *     "amountInEuros": "10.00"
   *   },
   *   "payment": {
   *     "type": "ideal",
   *     "orderDate": "2022-01-27T16:07:42.826Z",
   *     "paymentAmount": "10.00",
   *     "repetition": "one-time"
   *   },
   *   "status": "pending"
   * })
   * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
   */
  postIdealPaymentToBuckaroo(body) {
    return api.post(`${resource}/idealpayment`, body)
  },
  /**
   * Validate a payment and get the success or falsy response
   *
   * @method postIdealPaymentValidation
   * @param {Object} body
   *
   * @returns {Promise}
   *
   * @example $api.payments.postIdealPaymentValidation({
   *  buckarooData: 'BRQ_AMOUNT=10.00&BRQ_CURRENCY=EUR&BRQ_CUSTOMER_NAME=J.+de+T%C3%A8ster&BRQ_INVOICENUMBER=1643634337683276&BRQ_PAYER_HASH=ad663bfc8eef34e7d1b76ef1b051f3e1a4fb80a97b627efd23a337783fc8606b04b915b2ed24567f494a2bb802ec9a581bc987c80a2b37d373d73e29a386bb3e&BRQ_PAYMENT=4A83C58A7E004E5E995FA646C1B0D867&BRQ_PAYMENT_METHOD=ideal&BRQ_SERVICE_IDEAL_CONSUMERBIC=RABONL2U&BRQ_SERVICE_IDEAL_CONSUMERIBAN=NL44RABO0123456789&BRQ_SERVICE_IDEAL_CONSUMERISSUER=Rabobank&BRQ_SERVICE_IDEAL_CONSUMERNAME=J.+de+T%C3%A8ster&BRQ_SERVICE_IDEAL_TRANSACTIONID=0000000000000001&BRQ_STATUSCODE=190&BRQ_STATUSCODE_DETAIL=S001&BRQ_STATUSMESSAGE=Transaction+successfully+processed&BRQ_TEST=true&BRQ_TIMESTAMP=2022-01-31+14%3A05%3A39&BRQ_TRANSACTIONS=5FCEA76D76BC48409E11DD73A05F855E&BRQ_WEBSITEKEY=YLvINqx2D9&BRQ_SIGNATURE=9e63d6ce45ef4f6831ab2c6098baa1b3377dca78',
   *  popReceipt: 'AgAAAAMAAAAAAAAAqElKra0d2AE=',
   *  requestId: '6f072261-e003-0011-2cad-1de9b2000000',
   *  orderId: 'WEBAPP-MTD-1643883660-644',
   *  status: 'completed'
   *  organisation: 'MTD"
   * })
   *
   * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
   */
  postIdealPaymentValidation(body) {
    return api.post(`${resource}/validate`, body)
  },

  postIncasso(body) {
    return api.post(`${resource}/incasso`, body)
  },

  /**
   * Send a buckaroo status to store the status and statuscode in TableStorage
   *
   * @method postIdealPaymentValidation
   * @param {Object} body
   *
   * @returns {Promise}
   *
   * @example $api.payments.postStatus({
   *  orderId: 'WEBAPP-MTD-1643883660-644',
   *  statusCode: 192
   * })
   *
   * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
   */
  postStatus(body) {
    return api.post(`${resource}/status`, body)
  },
})

export const invoices = (api, resource) => ({
  /**
   * Get an invoice from Salesforce
   *
   * @method getInvoice
   * @param {String} invNr
   * @param {String} postalCode
   *
   * @returns {Promise}
   *
   * @example $api.invoices.getInvoice("INV04573470", "1234AB")
   *
   * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
   */
  async getInvoice(invNr, postalCode) {
    return api.get(`${resource}/${invNr}/${postalCode}`)
  },
})

/**
 * Repository (API) factory function built on axios for POMS endpoints
 * @requires axios, bugsnag
 * @param {Object} httpClient - Nuxt axios plugin
 * @param {Object} reporter - Nuxt bugsnag plugin
 * @param {String} resource - repository resource name
 * @returns {Object} repository - axios API request wrapper Object that exposes API methods
 * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
 */
export const poms = (api, resource) => ({
  playables: {
    /**
     * getAll
     * @method all
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.poms.playables.getAll({ descendantOf: 'VPWON_1257874' }) // returns response
     */
    async getAll(params) {
      return api.get(`${resource}/playables`, { params })
    },
  },
  scheduledBroadcasts: {
    /**
     * getAll
     * @method all
     * @param {Object} params - optional parameters for API request
     * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
     * @example $api.poms.scheduledBroadcasts.getAll({ start: '2022-12-01T00:00:00+01:00' , end: '2022-12-08T00:00:00+01:00' , offset: 0 }) // returns response
     */
    async getAll(params) {
      return api.get(`${resource}/scheduledbroadcasts`, { params })
    },
  },
})

/**
 * Repository (API) factory function built on axios for adding objects in a queue
 * @requires axios, bugsnag
 * @param {Object} httpClient - Nuxt axios plugin
 * @param {Object} reporter - Nuxt bugsnag plugin
 * @param {String} resource - repository resource name
 * @returns {Object} repository - axios API request wrapper Object that exposes API methods
 * @example legacyFunctions($axios, 'programs') // returns {repository} with resource 'programs'
 * @see https://api-docs.eo.nl - Swagger OpenAPI documentation for EO-API
 */
export const queue = (api, resource) => ({
  /**
   * Add an object to the queue
   * @method addObject
   * @param {String} path
   * @param {Object} body
   * @returns {Promise}
   * @example $api.salesforce.addObject('queuedorders', {product: 'MTD-ALG'})
   */
  addObject(path, body) {
    return api.put(`${resource}/${path}`, body)
  },
})

export const vacancy = (api, resource) => ({
  /**
   * posts a vacancy applience form
   * @method
   * @param {Object} id
   * @param {Object} data
   * @param {Object} config
   * @returns {Promise}
   * @example $api.vacancy.postApply(id, formData) // returns response (data: { message: 'OK' })
   */
  postApply(id, data, config) {
    return api.post(`${resource}/${id}`, data, config)
  },
})

export const mailing = (api) => ({
  /**
   * Subscribe to mailing
   * @method
   * @param {String} campaignId
   * @param {String} email
   */
  subscribe(campaignId, email) {
    if (!campaignId || !email) {
      return 'Missing required campaignId or email'
    }
    return api.post('salesforce/addtocampaign', {
      campaignId,
      email,
    })
  },
  /**
   * Unsubscribe to mailing
   * @method
   * @param {String} campaignId
   * @param {String} email
   * @param {String} contactId
   */
  unsubscribe(campaignId, email, contactId) {
    if (!campaignId || !contactId) {
      return 'Missing required campaignId or contactId'
    }
    return api.post('salesforce/optoutfromcampaign', {
      campaignId,
      contactId,
      email,
    })
  },
})

export const recruitment = (api, resource) => ({
  /**
   * gets all vacancies
   * @method getAllVacancies
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> {  }
   * @example $api.recruitment.getAllVacancies() // returns response
   */
  getAllVacancies(params) {
    return api.get(`${resource}/vacancies`, { params })
  },

  /**
   * gets single vacancy by id
   * @method getSingleVacancy
   * @param {String} id - id of requested resource
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: item }
   * @example $api.programs.getSingleVacancy('webdeveloper') // returns response
   */
  getSingleVacancy(id, params) {
    return api.get(`${resource}/vacancies/${id}`, { params })
  },
})

export const user = (api, resource) => ({
  /**
   * gets all on a specific path of the resource
   * @param {String} path
   * @param {Object} params - optional parameters for API request
   * @returns {Promise} response<Object> { data: { meta: Object, items: Array<item> } }
   * @example $api.user.getPathParam('/events', {preprCustomerId: '5f857913', contentType: 'personen', eventType: 'subscribe'}) // returns response
   */
  getPathParam(path, params) {
    return api.get(`${resource}${path}`, { params })
  },
})
